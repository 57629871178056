const download = {
  CSVExport: (data, fileName, extension = '.csv') => {
    const encodedUri = encodeURIComponent(data)
    const csvDataForExport = "data:text/csv;charset=utf-8," + encodedUri
    const link = document.createElement("a")
    link.setAttribute("href", csvDataForExport)
    link.setAttribute("target", "_blank")
    link.setAttribute("download", fileName + extension)
    document.body.appendChild(link)
    link.click()
  },
}

export default download
