<template>
  <div v-if="data.filter !== null" class="d-flex flex-column gap-4">
    <div
      class="d-flex flex-wrap justify-content-between gap-4 bg-white rounded-30 p-2"
    >
      <div class="d-flex gap-2">
        <div class="action-panel-search visitor-log-search-input w-300px">
          <InputField
            prepend-icon="ri-search-line"
            placeholder="Search visitors"
            v-model="data.filter.search"
            @update:model-value="() => onFilterChange(true)"
          />
        </div>
        <div class="action-panel-sort">
          <IconButton @click="toggleFilterVisibility" icon="ri-filter-line" />
        </div>
      </div>

      <div class="d-flex gap-1">
        <!-- EMAIL EXPORT BUTTON -->
        <IconButton @click="onEmailExportClick" icon="ri-mail-line" />
        <!-- EMAIL EXPORT BUTTON -->

        <!-- DOWNLOAD EXPORT BUTTON -->
        <IconButton @click="onDownloadExport" icon="ri-download-2-line" />
        <!-- DOWNLOAD EXPORT BUTTON -->
      </div>
    </div>
    <div
      v-if="isFilterVisible"
      class="d-flex flex-wrap justify-content-between gap-4"
    >
      <div class="d-flex flex-wrap gap-4">
        <div class="w-300px">
          <CustomSelect
            :close-on-select="true"
            placeholder="All status"
            v-model="filterStatus"
            :options="filterStatusOptions"
            @update:model-value="onFilterChange"
          />
        </div>
        <div class="w-300px">
          <CustomSelect
            :close-on-select="true"
            placeholder="All reasons for visitl"
            v-model="filterReasonForVisit"
            :options="filterReasonForVisitOptions"
            @update:model-value="onFilterChange"
          />
        </div>
        <div v-if="isDestinationEnabled" class="w-300px">
          <CustomSelect
            :close-on-select="true"
            placeholder="All destinations"
            v-model="filterDestination"
            :options="filterDestinationOptions"
            @update:model-value="onFilterChange"
          />
        </div>
        <div class="w-300px">
          <VisitorLogDateRangePicker
            class="w-300px"
            v-model="data.filter.dates"
            :date-ranges="optionalDateRanges"
            @update:model-value="onFilterChange"
          ></VisitorLogDateRangePicker>
        </div>
      </div>

      <div class="d-flex gap-4">
        <BaseButton type="button" rounded @click="onFilterReset">
          Clear all
        </BaseButton>
        <BaseButton solid rounded @click="onFilterChange">Submit</BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/v3components/shared/Form/InputField.vue"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import VisitorLogDateRangePicker from "@/v3components/Datatables/Visitor/CheckIns/VisitorLogDateRangePicker.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import IconButton from "@/v3components/shared/Buttons/IconButton.vue"
import visitorConstants from "@/constants/visitorConstants"
import {
  computed,
  onMounted,
  reactive,
  watch,
  ref,
  inject,
  defineAsyncComponent
} from "vue"
import { useStore } from "vuex"
import moment from "moment-timezone"
import helpers from "@/helpers/index"
import visitorConfigs from "@/configs/visitorConfigs"

const VisitorExportReportPopUp = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Datatables/Visitor/CheckIns/VisitorExportReportPopUp.vue"
    )
)

export default {
  name: "VisitorLogHistoryTableFilters",
  components: {
    InputField,
    CustomSelect,
    VisitorLogDateRangePicker,
    BaseButton,
    IconButton
  },
  props: {
    filter: {
      type: Object,
      default: () => null
    }
  },
  emits: ["onFilterChange", "onFilterReset"],
  setup(props, { emit }) {
    const store = useStore()
    const modal = inject("modal")

    const isFilterVisible = ref(true)
    const data = reactive({
      filter: null
    })

    const school = computed(() => store.getters["visitorSettings/school"])

    const visitorSchoolData = computed(() => {
      return school?.value?.data || null
    })

    const destinationSetting = computed(() => {
      return (
        visitorSchoolData.value?.building_settings?.find(
          (setting) =>
            setting?.settings_id === visitorConstants.SETTINGS.DESTINATION
        ) || null
      )
    })

    const isDestinationEnabled = computed(() => {
      return destinationSetting?.value?.value === "true" || false
    })

    const maxDate = computed(() => {
      return new Date()
    })

    const minDate = computed(() => {
      const currentDate = new Date()
      const minYear =
        currentDate.getMonth() >= 7
          ? currentDate.getFullYear()
          : currentDate.getFullYear() - 1
      return new Date(minYear, 7, 1)
    })

    const optionalDateRanges = computed(() => {
      const stringRangeFunction = visitorConfigs?.useCustomVisitorTimeFunction
        ? helpers.stringDateRangeWithTimezone
        : stringRangeFunction
      return [
        {
          label: "Last 7 days",
          id: "",
          range: stringRangeFunction(
            moment().subtract(7, "days") < moment(minDate.value)
              ? moment(minDate.value)
              : moment().subtract(7, "days"),
            moment()
          )
        },
        {
          label: "Today",
          id: "",
          range: stringRangeFunction(moment(), moment())
        },
        {
          label: "Yesterday",
          id: "",
          range: stringRangeFunction(
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          )
        },
        {
          label: "Last 14 days",
          id: "",
          range: stringRangeFunction(
            moment().subtract(14, "days") < moment(minDate.value)
              ? moment(minDate.value)
              : moment().subtract(14, "days"),
            moment()
          )
        },
        {
          label: "Last 30 days",
          id: "",
          range: stringRangeFunction(
            moment().subtract(30, "days") < moment(minDate.value)
              ? moment(minDate.value)
              : moment().subtract(30, "days"),
            moment()
          )
        },
        {
          label: "Custom range",
          range: null,
          startDate: helpers.currTzDate(minDate.value),
          endDate: helpers.currTzDate(maxDate.value),
          id: "custom"
        }
      ]
    })

    const filterStatus = computed({
      get: () => {
        if (data.filter.status) {
          return data.filter.status
        } else {
          return {
            label: "All status",
            value: null
          }
        }
      },
      set: (value) => {
        data.filter.status = value
      }
    })

    const filterStatusOptions = [
      { label: "All status", value: null },
      {
        label: "Signed In",
        value: visitorConstants.CHECK_IN_FORM.FILTER_VISIT_STATUS_SIGNED_IN
      },
      {
        label: "Signed Out",
        value: visitorConstants.CHECK_IN_FORM.FILTER_VISIT_STATUS_SIGNED_OUT
      },
      {
        label: "Denied",
        value: visitorConstants.CHECK_IN_FORM.FILTER_VISIT_STATUS_DENIED
      }
    ]

    const filterReasonForVisit = computed({
      get: () => {
        if (data.filter.reason_for_visit) {
          return data.filter.reason_for_visit
        } else {
          return {
            label: "All reasons for visit",
            value: null
          }
        }
      },
      set: (value) => {
        data.filter.reason_for_visit = value
      }
    })

    const reasons = computed(() => store.getters["visitorManage/reasons"])

    const filterReasonForVisitOptions = computed(() => {
      const exisitingReasons =
        reasons?.value?.existing?.map((reason) => {
          return {
            label: reason?.lookup_value + " - " + "Active",
            value: { ...reason, existing: true, old: false }
          }
        }) || []
      const oldReasons =
        reasons?.value?.old?.map((reason) => {
          return {
            label: reason?.lookup_value + " - " + "Inactive",
            value: { ...reason, existing: false, old: true }
          }
        }) || []
      return [
        { label: "All reasons for visit", value: null },
        ...exisitingReasons,
        ...oldReasons
      ]
    })

    const filterDestination = computed({
      get: () => {
        if (data.filter.destination) {
          return data.filter.destination
        } else {
          return {
            label: "All destinations",
            value: null
          }
        }
      },
      set: (value) => {
        data.filter.destination = value
      }
    })

    const destinations = computed(
      () => store.getters["visitorManage/destinations"]
    )

    const filterDestinationOptions = computed(() => {
      const exisitingDestinations =
        destinations?.value?.all_destinations?.existing?.map((dest) => {
          return {
            label: dest?.displayText + " - " + "Active",
            value: { ...dest, existing: true, old: false }
          }
        }) || []
      const oldDestinations =
        destinations?.value?.all_destinations?.old?.map((dest) => {
          return {
            label: dest?.displayText + " - " + "Inactive",
            value: { ...dest, existing: false, old: true }
          }
        }) || []
      return [
        { label: "All destinations", value: null },
        ...exisitingDestinations,
        ...oldDestinations
      ]
    })

    const visitors = computed(() => store.getters["visitorManage/visitors"])

    const isLoading = computed(() => visitors?.value?.isLoading || false)

    const onFilterChange = (shouldReset) => {
      emit("onFilterChange", data.filter, shouldReset)
    }

    const onFilterReset = () => {
      data.filter = null
      emit("onFilterReset")
    }

    const toggleFilterVisibility = () => {
      isFilterVisible.value = !isFilterVisible.value
    }

    const onEmailExportClick = () => {
      modal.open(VisitorExportReportPopUp, {
        size: "sm",
        title: visitorConstants.CHECK_IN_FORM.MODAL_TITLE_EMAIL_REPORT,
        props: {
          type: "email",
          filter: data.filter,
          isDestinationEnabled: isDestinationEnabled.value
        }
      })
    }

    const onDownloadExport = () => {
      modal.open(VisitorExportReportPopUp, {
        size: "sm",
        title: visitorConstants.CHECK_IN_FORM.MODAL_TITLE_DOWNLOAD_REPORT,
        props: {
          type: "download",
          filter: data.filter,
          isDestinationEnabled: isDestinationEnabled.value
        }
      })
    }

    onMounted(() => {
      data.filter = props?.filter || null
    })

    watch(
      () => props.filter,
      (val) => {
        data.filter = val
      }
    )

    return {
      data,
      isLoading,
      isDestinationEnabled,
      isFilterVisible,
      filterStatus,
      filterStatusOptions,
      filterReasonForVisit,
      filterReasonForVisitOptions,
      filterDestination,
      filterDestinationOptions,
      optionalDateRanges,
      onFilterChange,
      onFilterReset,
      onEmailExportClick,
      onDownloadExport,
      toggleFilterVisibility
    }
  }
}
</script>

<style scoped>
.w-300px {
  width: 300px;
}
</style>
