<template>
  <div
    v-if="!hideBanner"
    class="d-flex flex-column mb-4 gap-2 align-content-center justify-content-center text-center"
  >
    <p v-if="heading" class="text-muted m-0">
      {{ heading }}
    </p>
    <div
      v-if="linkText && link"
      class="d-flex justify-content-center align-items-center"
    >
      <CButton
        v-if="linkText"
        class="btn bg-gradient-blue text-white"
        @click="onBannerButtonAction"
      >
        {{ linkText }}
      </CButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecurlyMarketingBanner",
  props: {
    hideBanner: {
      type: Boolean,
      default: true
    },
    heading: {
      type: String,
      default: ""
    },
    linkText: {
      type: String,
      default: ""
    },
    link: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const onBannerButtonAction = () => {
      if (!props.link) return

      window.open(props.link, "_blank")
    }
    return { onBannerButtonAction }
  }
}
</script>
