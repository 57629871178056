<template>
  <CAlert v-if="showAlert" :color="alertType" :show="showAlert">
    {{ alertMessage }}
  </CAlert>
</template>

<script>
import { onMounted, onUnmounted, ref, computed } from "vue"
export default {
  name: "CustomAlert",
  props: {
    alert: {
      type: Object,
      default: null
    },
    keepAlive: {
      type: Boolean,
      default: false
    },
    keepShort: {
      type: Boolean,
      default: false
    }
  },
  emits: ["done"],
  setup(props, { emit }) {
    const timeoutId = ref(null)

    const alertType = computed(() => {
      return props?.alert?.type || ""
    })

    const alertMessage = computed(() => {
      return props?.alert?.message || ""
    })

    const showAlert = computed(() => {
      return props?.alert !== null || false
    })

    onMounted(() => {
      const isSuccess = props?.alert?.type === "success" || false
      const time = isSuccess
        ? 1000
        : props.keepShort
          ? 3000
          : props.keepAlive
            ? 30000
            : 10000
      timeoutId.value = setTimeout(() => {
        emit("done")
      }, time)
    })

    onUnmounted(() => {
      if (timeoutId.value) clearTimeout(timeoutId.value)
    })

    return { showAlert, alertMessage, alertType }
  }
}
</script>

<style scoped></style>
